<template>
  <CrudTable
    v-bind="crudTableConfig"
    :storeModule="storeModule"
    :showActionsPanel="false"
    >

    <template v-slot:form="{close}">
      <DetachmentsOfflineOnlineForm showHeader @close="close"/>
    </template>

    <template v-slot:item.verbose_name="{ item }">
      <router-link :to="{ name: 'DetachmentsOfflineOnlineDetail', params: { id: item.id }}">
        {{ item.verbose_name }}
      </router-link>
    </template>
  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import DetachmentsOfflineOnlineForm from '../forms/DetachmentsOfflineOnlineForm'
import { mapState } from 'vuex'

export default {
  name: 'DetachmentsOfflineOnlineTable',
  components: { CrudTable, DetachmentsOfflineOnlineForm },
  data () {
    return {
      storeModule: 'detachmentsOfflineOnline'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    })
  }
}
</script>

<style scoped>

</style>
