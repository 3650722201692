<template>
<Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <DetachmentsOfflineOnlineTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import DetachmentsOfflineOnlineTable from '../components/tables/DetachmentsOfflineOnlineTable'
import sections from './defaultNavigationDrawerSections'

export default {
  name: 'DetachmentsOfflineOnlineList',
  components: { DetachmentsOfflineOnlineTable, Layout },
  data () {
    return {
      sections: sections,
      title: this.$t('detachments.detachmentsTitle')
    }
  }
}
</script>

<style scoped>

</style>
